import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import App from './App/App';
import AppEn from './App/AppEn';
import SmoothScroll from 'smooth-scroll';
import * as serviceWorker from './serviceWorker';


// ReactDOM.render(<App long={true}/>, document.getElementById('root'));
// ReactDOM.render(<AppEn long={true} />, document.getElementById('root'));
// ReactDOM.render(<App long={false} />, document.getElementById('root'));
ReactDOM.render(<AppEn long={false}/>, document.getElementById('root'));
new SmoothScroll('a[href*="#"]');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
