import React from 'react';
import Section from "components/Section";

import heroImage from "images/concentrating-in-front-of-monitor.jpg";

import challengeIcon from "images/icons/challenge.png";
import reportIcon from "images/icons/report.png";
import toTheSkyIcon from "images/icons/to-the-sky.png";

import medicalIcon from "images/icons/round/256_pills.png";
import financialIcon from "images/icons/round/256_man-money.png";
import growthIcon from "images/icons/round/256_plant.png";

import mailIcon from "images/icons/round/256_mailbox.png";
import phoneIcon from "images/icons/round/256_iphone.png";

import targetIcon from "images/icons/target.png";
import brainstormingIcon from "images/icons/brainstorming.png";
import successIcon from "images/icons/success.png";

import paperworkIcon from "images/icons/paperwork.png";
import budgetIcon from "images/icons/budget.png";
import partnershipIcon from "images/icons/partnership.png";
import appLookIcon from "images/icons/app-look.png";
import approvedIcon from "images/icons/approved.png";
import serviceIcon from "images/icons/service.png";
import dataAnalysisIcon from "images/icons/data-analysis.png";

import calculatorIcon from "images/icons/round/256_calculator.png";
import magnifyingMoneyIcon from "images/icons/round/256_magnifier-money.png";
import timeMoneyIcon from "images/icons/round/256_coin-clock.png";

import menuLogo from "images/SprinTech-white-logotype.png";

import './App.scss';
import Button from "../components/Button";
import IconBox from "../components/IconBox";



function AppEn({long=true}) {
  const heroStyle = {
    backgroundImage: `url(${heroImage})`,
  };

  return (
    <div className="App">
      <div className="menu">
        <div className="logo">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">
            <img src={menuLogo} alt="SprinTECH logo" />
          </a>
        </div>
        <div className="links">
          <a href="#about-sprintech">What do we do?</a>
          <a href="#how-we-work">How do we work?</a>
          {long && <><a href="#pricing">Pricing</a>
          <a href="#case-studies">Use cases</a></>}
          <a href="#contact">Contact</a>
        </div>
      </div>
      <Section id="hero"
        fullHeight={true}
        darkerBg={true}
        style={heroStyle}
      >
        <h1 className="title">Sprin<span className="sprint-colored">TECH</span> Learning</h1>
        <h2 className="subtitle">Learning has never been easier</h2>
        <Button
          large={true}
          text="What do we do?"
          href="#about-sprintech"
        />
      </Section>
      <Section id="about-sprintech"
        fullHeight={true}
      >
        <div className="card">
          <h1>What do we do?</h1>
          <div className="icons">
            <IconBox
              iconPath={challengeIcon}
            >
              <p>We create Sprints - short, interactive apps that improve or test your team's knowledge.</p>
            </IconBox>
            <IconBox
              iconPath={reportIcon}
            >
              <p>We analyze the team's results and present a detailed report.</p>
            </IconBox>
            <IconBox
              iconPath={toTheSkyIcon}
            >
              <p>We show knowledge gaps that you need to address to increase your employees' performance.</p>
            </IconBox>
          </div>
          <div className="actions">
            <Button className="accent"
              large={true}
              text="Try out a Sprint!"
              href="https://en.demo.sprin.tech/first-aid"
              newWindow={true}
            />
            <Button
              large={true}
              text="How do we work?"

              href="#how-we-work"
            />
          </div>
        </div>
      </Section>
      <Section id="how-we-work"
        fullHeight={true}
      >
        <div className="card">
          <h1>Cooperation model</h1>
          <div className="work-steps">
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={paperworkIcon}
            >
              <div className="step">
                1
              </div>
              <div className="description">
                You send us materials that you want to turn into a Sprint.
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={budgetIcon}
            >
              <div className="step">
                2
              </div>
              <div className="description">
                We calculate how many hours are needed to create the Sprint and when we can deliver it.
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={partnershipIcon}
            >
              <div className="step">
                3
              </div>
              <div className="description">
                After you accept the cost and deadline, we start working.
                <strong> On average, we need just 6 to 8 hours to create a Sprint!</strong>
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={appLookIcon}
            >
              <div className="step">
                4
              </div>
              <div className="description">
                We send the Sprint for you to accept.
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={approvedIcon}
            >
              <div className="step">
                5
              </div>
              <div className="description">
                We make adjustments if needed and set a date when the Sprint will be running.
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={serviceIcon}
            >
              <div className="step">
                6
              </div>
              <div className="description">
                We provide technical support, while the Sprint is running. You have a "hotline"!
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={dataAnalysisIcon}
            >
              <div className="step">
                7
              </div>
              <div className="description">
                After the Sprint, we send you a report with the answers to the Sprint questions analyzed.
              </div>
            </IconBox>
          </div>
          {long && <div className="actions">
            <Button
              large={true}
              text="See pricing"
              href="#pricing"
            />
          </div>}
          {!long && <div className="actions">
            <Button
              large={true}
              text="Skontaktuj się z nami!"
              href="#contact"
            />
          </div>}
        </div>
      </Section>
      {long && <>
      <Section id="pricing"
        fullHeight={true}
      >
        <div className="card">
          <h1>Pricing</h1>
          <div className="work-steps">
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={calculatorIcon}
            >
              <div className="description">
                The cost of a Sprint is based on the <strong>materials you send us</strong>.
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={magnifyingMoneyIcon}
            >
              <div className="description">
                We estimate how many hours of our teams’ work is required to deliver the whole package: <strong>exercises</strong>, <strong>technical support</strong> and the <strong>results report</strong>.
              </div>
            </IconBox>
            <IconBox className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={timeMoneyIcon}
            >
              <div className="description">
                The average Sprint is just <strong>6-8 hours</strong> of our work, billed <strong>380zł/96$ for an hour</strong>.
              </div>
            </IconBox>
          </div>
          <div className="actions">
            <Button
              large={true}
              text="See Case Studies"
              href="#case-studies"
            />
          </div>

        </div>
      </Section>
      <Section id="case-studies"
        fullHeight={true}
      >
        <div className="card">
          <h1>Case Studies</h1>
          <h3>We used Sprints in a few projects. Take a look at the results:</h3>
          <div className="icons">
            <a href="#case-study-pharmaceutical" className="no-style">
              <IconBox className="case-study-icon-box"
                position={IconBox.POSITIONS.LEFT}
                iconPath={medicalIcon}
              >
                <h3>Product knowledge test</h3>
                <p>A project created for a pharmaceutical company. The aims were to verify and broaden the knowledge of their sales representatives.</p>
              </IconBox>
            </a>
            <a href="#case-study-financial" className="no-style">
              <IconBox className="case-study-icon-box"
                position={IconBox.POSITIONS.LEFT}
                iconPath={financialIcon}
              >
                <h3>Personal development workshops</h3>
                <p>A project created for a sales department of a major technological company. The aim was to increase the personal qualifications of the team members.</p>
              </IconBox>
            </a>
            <a href="#case-study-growth" className="no-style">
              <IconBox className="case-study-icon-box"
                position={IconBox.POSITIONS.LEFT}
                iconPath={growthIcon}
              >
                <h3>Fostering the growth mindset</h3>
                <p>An open-access project addressed to parents. It aimed to promote the knowledge about simple and effective communication with children that develops the growth mindset.</p>
              </IconBox>
            </a>
          </div>
          <div className="actions">
            <Button
              large={true}
              text="Go to the first case study"

              href="#case-study-pharmaceutical"
            />
          </div>
        </div>
      </Section>
      <Section id="case-study-pharmaceutical" className="case-study-details"
        fullHeight={true}
      >
        <div className="card case-study">
          <div className="info">
            <h4 className="count">Case study 1</h4>
            <h1 className="title">Product knowledge test</h1>
          </div>
          <div className="content">
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={targetIcon}
            >
              <strong>Aims:</strong> Verifying and completing Sales Representatives' knowledge
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={brainstormingIcon}
            >
              <strong>Execution:</strong> Series of Sprints, each consisted of:
              <ul>
                <li>Writing test questions based on the company's materials</li>
                <li>Consulting and approvement of the questions</li>
                <li>Conducting <strong>a knowledge test #1</strong> and <strong>a report</strong> showing which parts of product knowledge the team excelled at and which needed improving</li>
                <li>Conducting <strong>a knowledge test #2</strong> for team members who did not achieve a satisfying result (after a week)</li>
                <li>Conducting <strong>a consolidation test</strong> to strengthen the knowledge (after a month)</li>
              </ul>
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={successIcon}
            >
              <strong>Results:</strong> Significant improvement over the course of a few rounds of Sprints:
              <ul>
                <li><strong>25%</strong> - on average fail the knowledge test #1</li>
                <li><strong>100%</strong> - everyone passes the knowledge test #2</li>
                <li><strong>from 5% to 35%</strong> - an increase of employees who achieved a perfect score in the knowledge test #1 after three rounds of Sprints</li>
                <li><strong>100%</strong> - currently all team members pass the consolidation test</li>
                <li><strong>from 58% to 81%</strong> - an increase of employees who achieved a perfect score in the consolidation test after three rounds of Sprints!</li>
              </ul>
            </IconBox>
          </div>
          <div className="actions">
            <Button className="return"
              large={true}
              text="Return to the Case Studies list"

              href="#case-studies"
            />
            <Button
              large={true}
              text="Go to the next Case Study"

              href="#case-study-financial"
            />
          </div>
        </div>
      </Section>
      <Section id="case-study-financial" className="case-study-details"
        fullHeight={true}
      >
        <div className="card case-study">
          <div className="info">
            <h4 className="count">Case study 2</h4>
            <h1 className="title">Personal development workshops</h1>
          </div>
          <div className="content">
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={targetIcon}
            >
              <strong>Aim:</strong> Increasing participants' engagement
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={brainstormingIcon}
            >
              <strong>Execution:</strong> 2 days of workshops with Sprints
              <ul>
                <li>4 trainers, 50 participants</li>
                <li>Each participant's Sprint results summed in a ranking</li>
                <li>Sprints were used in:</li>
                <ul>
                  <li>a prework</li>
                  <li>an introduction to the workshops</li>
                  <li>theme blocks summaries</li>
                  <li>filling knowledge gaps</li>
                </ul>
              </ul>
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={successIcon}
            >
              <strong>Results:</strong>
              <ul>
                <li><strong>High engagement</strong> from the first to last minutes of the workshops</li>
                <li><strong>Quick filling</strong> of the critical gaps in knowledge</li>
                <li><strong>98%</strong> of participants rated Sprints as very useful</li>
              </ul>
            </IconBox>
          </div>
          <div className="actions">
            <Button className="return"
              large={true}
              text="Return to the Case Studies list"

              href="#case-studies"
            />
            <Button
              large={true}
              text="Go to the next Case Study"

              href="#case-study-growth"
            />
          </div>
        </div>
      </Section>
      <Section id="case-study-growth" className="case-study-details"
        fullHeight={true}
      >
        <div className="card case-study">
          <div className="info">
            <h4 className="count">Case study 3</h4>
            <h1 className="title">Fostering the growth mindset</h1>
          </div>
          <div className="content">
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={targetIcon}
            >
              <strong>Aim:</strong> Teaching a few simple and effective statements developing the growth mindset
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={brainstormingIcon}
            >
              <strong>Execution:</strong> A Sprint with open access, promoted via Facebook Ad
              <ul>
                <li>The average duration of the Sprint: 6 minutes</li>
                <li>The Sprint consists of 4 parts:</li>
                <ul>
                  <li><strong>The pre-test</strong> evaluating the growth vs fixed mindset dominance</li>
                  <li><strong>The exercise explaining</strong> differences between the growth and fixed mindset</li>
                  <li><strong>The exercise teaching</strong> how to communicate to foster the growth mindset</li>
                  <li><strong>The post-test</strong> evaluating learning effects</li>
                </ul>
              </ul>
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={successIcon}
            >
              <strong>Results:</strong>
              <ul>
                <li>Around <strong>390</strong> people completed the Sprint</li>
                <li>The pre-test: about <strong>65%</strong> of answers showed the dominance of the fixed mindset</li>
                <li>The post-test: about <strong>67%</strong> correct answers</li>
                <li>2 significant errors in building effective statements identified</li>
              </ul>
            </IconBox>
          </div>
          <div className="actions">
            <Button className="return"
              large={true}
              text="Return to the Case Studies list"

              href="#case-studies"
            />
            <Button className="accent"
              large={true}
              text="Check out the sprint!"
              newWindow={true}

              href="https://en.demo.sprin.tech/fostering-growth"
            />
            <Button
              large={true}
              text="What's next?"

              href="#contact"
            />
          </div>
        </div>
      </Section>
      </>}
      <Section id="contact"
        fullHeight={true}
      >
        <div className="card">
          <h1>Contact us!</h1>
          <p>If you are interested in trying out Sprints in your company or would like to find out more about how Sprints can help improve your business, contact us!</p>
          <div className="work-steps">
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={phoneIcon}
            >
              <h3>By phone:</h3>
              <p className="phone"><a href="tel:+48 504 024 433">+48 513 504 785</a></p>
            </IconBox>
            <IconBox className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={mailIcon}
            >
              <h3>By email:</h3>
              <p className="phone"><a href="mailto:magda@sprin.tech">magda@sprin.tech</a></p>
            </IconBox>
          </div>
        </div>
      </Section>
      <footer>
        <p>SprinTECH Learning Sp. z o.o., ul. Szklarniowa 5c, 05-077 Warsaw</p>
        <p>KRS: 0000319088; NIP: 701-01-60-393; Share capital: 60 000 PLN</p>
      </footer>
    </div>
  );
}

export default AppEn;
