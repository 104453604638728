import React from 'react';
import classNames from 'classnames';

import "./IconBox.scss";

export default function IconBox({className, iconPath, children, position="top"}) {
  return <div className={classNames("IconBox", className, position)}>
    <img src={iconPath} alt="icon" className="icon"/>
    <div className="content">
      {children}
    </div>
  </div>
}

IconBox.POSITIONS = {
  TOP: "top",
  LEFT: "left",
};